@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.messageContainer {
  display: none;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include mixin.desktop {
    display: flex;
  }
}

a {
  text-decoration: none;
  color: $whiteColor;

  &:hover {
    color: rgba(255, 255, 255, 0.629);
    text-decoration: underline;
  }
}

.userMenuContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-right: 15px;
}

.userMenuContainer:hover {
  color: $primaryColor;
  cursor: pointer;
}

.hideShowUserMenuButton {
  color: $primaryColor;
  background: $whiteColor;
  border: $whiteColor;
}

.hideShowUserMenuButton:hover {
  color: $whiteColor !important;
  background-color: $blackColor !important;
}

.hideShowUserMenuButton:focus {
  box-shadow: 0 0 0 0 $blackColor !important;
}

.userName {
  font-size: 0.8rem;
  font-weight: bold;
}

.userLogo {
  font-size: 2rem;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.buttonCustom,
.buttonCustom:hover,
.buttonCustom:focus,
.buttonCustom:active {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
