@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';
.containerTransactionError {
  display: flex;
  flex-direction: column;
}

.containerErrorT {
  display: flex;
  align-items: center;
  width: 762px;
  height: 78px;
  margin: 30px 37px 0px 30px;
  background-color: $errorColorBkg;
  padding-left: 30px;
  gap: 15px;
}

.labelErrorT {
  font-size: 16px;
  font-weight: 700;
  color: #be0001 !important;
}
.labelErrorD {
  font-size: 14px;
  font-weight: normal;
  color: #be0001 !important;
}
