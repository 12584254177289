@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.processedDisbursementWorkingArea {
  border-radius: 9px;
  border: solid 2px $lightLineColor;
  background-color: $whiteColor;
  margin: 0 0 28px 0;
  margin-top: 50px;
  width: 894px;
  height: auto;
}

.disbursementContainerData {
  padding-left: 32px;
}
.subTitle {
  font-size: 30px;
  color: $primaryColor !important;
  margin-bottom: 20px;
}

.containerDataProcessed {
  width: 429px;
  height: 95px;
  padding: 10px 0px 15px 33px;
  margin-left: 36px;
  background: #f0f1ff;
  border-left: 5px solid $secondaryColor;
}

.inputDescriptionP {
  border: none !important;
  font-size: 16px;
  font-weight: 700;
  color: $primaryColor !important;
  background: transparent;
  opacity: unset !important;
}
.informationText {
  font-size: 16px;
  color: $exampleTextColor;
  font-style: normal;
}

.containerInformation {
  margin-left: 36px;
}
.containerLoadingInformation {
  display: flex;
  align-items: center;
  gap: 150px;
  padding: 10px 18px 25px 10px;
}
