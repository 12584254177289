.p-paginator {
  display: flex;
  justify-content: flex-end;
}

.p-datatable {
  min-width: 60rem;
  width: 400px;
  margin: 0 auto;

  background-color: blue;
}

.table {
  background-color: white !important;
}

.amountField {
  justify-content: flex-end;
}
