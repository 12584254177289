@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.newDisbursementWorkingArea {
  border-radius: 9px;
  border: solid 2px $lightLineColor;
  background-color: $whiteColor;
  margin: 0 0 28px 0;
  margin-top: 50px;
  width: 826px;
  height: 457px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.containerData {
  padding: 5px 8px 0px 32px;
}

.divider {
  padding-top: 20px;
  border-bottom: 1px solid #d9d9d9;
}

.subTitle {
  margin-bottom: 10px;
  font-size: 24px;
  color: #170a33;
}

.containerDescription {
  color: $whiteColor;
  padding: 0px 37px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.description {
  font-size: 16px;
  margin: 0px;
}

.inputDescription {
  border: none !important;
  font-size: 16px;
  font-weight: 700;
  color: #170a33 !important;
  width: 100%;
}
.labelContainer {
  font-size: 16px;
  color: #170a33 !important;
  font-weight: normal;
}
.labelContainerAmount {
  font-size: 16px;
  color: #170a33 !important;
  font-weight: normal;
  width: 250px;
}
.containerEmail {
  display: flex;
  justify-content: center;
  align-items: center;
}
