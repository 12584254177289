@import '@styles/variables.scss';

.transactionTypeLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 27px;
  padding: 0.5em;
  gap: 2px;
  border-radius: 5px;
  border: solid 1px $primaryColor;
  background-color: $secondaryBackgroundColor;
}

.icon {
  padding-right: 0.3em;
  font-size: 12px;
}

.black {
  color: black;
}

.orange {
  color: #f78310;
}

.green {
  color: #02b920;
}

.purple {
  color: #a355ff;
}

.blue {
  color: #167fe8;
}

.red {
  color: #fa2a2a;
}

.blackBorder {
  border: solid 1px black;
}

.orangeBorder {
  border: solid 1px #f78310;
}

.greenBorder {
  border: solid 1px #02b920;
}

.purpleBorder {
  border: solid 1px #a355ff;
}

.blueBorder {
  border: solid 1px #167fe8;
}

.redBorder {
  border: solid 1px #fa2a2a;
}
