@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&display=swap');
@import './styles/variables.scss';

@font-face {
  font-family: 'ApercuPro';
  src: local('ApercuPro'), url('./fonts/ApercuPro-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'ApercuPro';
  src: local('ApercuPro'),
    url('./fonts/ApercuPro-Regular.ttf') format('truetype');
  font-weight: normal;
}

.body,
.p-component,
#root {
  font-family: 'ApercuPro', 'Plus Jakarta Sans', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif', cursive;
}

.p-card .p-card-content {
  padding: 0 !important;
}

.p-progress-spinner-circle {
  stroke: $secondaryColor !important;
}
