@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.customCard {
  margin-bottom: 10px !important;
}

.mainSection {
  margin-top: 50px;
  display: flex;
  height: 100%;
  width: 100%;
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 40;
  margin-bottom: 10px;
}

.headerText {
  font-size: 45px;
  color: $primaryColor;
}

.exportButton {
  background: $secondaryColor;
  border: solid $blackColor 3px;
  color: $blackColor !important;
  margin: 10px 0 10px 0;
  width: 141px;
  height: 43px;
}

.exportButton:hover {
  background-color: $primaryColorHover !important;
  border: solid $blackColor 3px;
  color: $blackColor !important;
  margin: 10px 0 10px 0;
}

.refreshButton:hover {
  color: $whiteColor !important;
  background-color: $blackColor !important;
}

.tableWorkingArea {
  border-radius: 9px;
  border: solid 2px $lightLineColor;
  background-color: $whiteColor;
  margin: 0 0 28px 0;
  padding: 37px 60px 12px 45px;
}

.mainSectionWorkArea {
  background-color: $primaryBackgroundColor;
  width: 100%;
}
