$activeColor: #f0f1ff;
$primaryBackgroundColor: #f7f7f7;
$blackColor: #000000;
$borderColor: #039be5;
$exampleTextColor: #6c7a9b;
$inputBackgroundColor: #ced4da;
$lightIconColor: #999999;
$lightLineColor: #d5d8db;
$lightTextColor: #7b7b7b;
$primaryColor: #170a33;
$primaryColorHover: #11ac95;
$redColor: #e60001;
$secondaryBackgroundColor: #f4f6f8;
$secondaryBackgroundColorHover: #e8e4e4;
$secondaryColor: #00ceff;
$warningColor: #e24c4c;
$whiteColor: #ffffff;
$catuionColor: #f65630;
$cautionColorBkg: #fff2e6;
$errorColorBkg: #ffe6e7;
$SecondaryMainColor: #00c3b1;
$error_bkg: #ffe6e7;
$errorStatus: #fa2a2a;
$completedStatus: #02b920;
