@use '@styles/mixin.scss' as mixin;


.navBarItem {
    color: gray;
    list-style-type: none;
    padding: 15px 20px 15px 20px;
}

.navBarItemSelected {
    color: black;
    border-left: solid black 0.5rem;
    padding: 15px 20px 15px 10px;
}
