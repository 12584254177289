@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.modal {
  width: 575px;
}

.modalTitle {
  font-size: 40px !important;
  color: $primaryColor !important;
  letter-spacing: 2.4px;
}

.modalText {
  font-size: 16px;
  letter-spacing: 0.96px;
  color: $lightTextColor;
}

.confirmButton {
  width: 100% !important;
  height: 43px;
  background-color: $primaryColor;
  color: $secondaryColor;
  border: none;
  margin: 0 0 0 10px !important;
}

.confirmButton:hover {
  background-color: $primaryColor !important;
  color: $secondaryColor !important;
  opacity: 0.8;
}

.cancelButton {
  width: 100% !important;
  height: 43px;
  background-color: $secondaryBackgroundColor;
  color: $primaryColor;
  border: none;
  margin: 0 10px 0 0 !important;
}

.cancelButton:hover {
  background-color: $secondaryBackgroundColorHover !important;
  color: $primaryColor !important;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
