@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.modal {
  width: 575px;
}

.modalTitle {
  font-size: 40px !important;
  color: $primaryColor !important;
  letter-spacing: 2.4px;
}

.modalText {
  font-size: 16px;
  letter-spacing: 0.96px;
  color: $lightTextColor;
}

.filterText {
  font-size: 14px;
  color: $primaryColor;
}

.exampleText {
  color: $exampleTextColor;
  font-size: 10px;
  line-height: 23px;
}

.calendarText {
  font-size: 14px;
  color: $primaryColor;
}

.asterisk {
  color: $warningColor;
}

.radiobutton {
  margin: auto !important;
  height: 21px !important;
  width: 22px;
}

.radiobuttonText {
  margin-left: 0.5em;
}

.confirmButton {
  width: 100% !important;
  height: 43px;
  background-color: $primaryColor;
  color: $secondaryColor;
  border: none;
  margin: 0 0 0 10px !important;
}

.confirmButton:hover {
  background-color: $primaryColor !important;
  color: $secondaryColor !important;
  opacity: 0.8;
}

.cancelButton {
  width: 100% !important;
  height: 43px;
  background-color: $secondaryBackgroundColor;
  color: $primaryColor;
  border: none;
  margin: 0 10px 0 0 !important;
}

.cancelButton:hover {
  background-color: $secondaryBackgroundColorHover !important;
  color: $primaryColor !important;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.radiobuttonContainer {
  display: flex;
  flex-direction: row;
  gap: 90px;
}

.textContainer {
  display: flex;
  flex-direction: row;
}

.dateError {
  display: block;
  font-size: 10px;
  line-height: 23px;
  color: $warningColor;
}
