@import '@styles/variables.scss';

.statusTypeLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 27px;
  padding: 0.5em;
  gap: 2px;
  border-radius: 5px;
  border: solid 1px $primaryColor;
}

.icon {
  padding-right: 0.3em;
  font-size: 12px;
}

.black {
  color: $blackColor;
}

.orange {
  color: #f78310;
  font-weight: bold;
  font-size: 14px;
}

.blue {
  color: $secondaryColor;
  font-weight: bold;
  font-size: 14px;
}
.green {
  color: $completedStatus;
  font-weight: bold;
  font-size: 14px;
}

.red {
  color: $errorStatus;
  font-weight: bold;
  font-size: 14px;
}

.blackBorder {
  border: solid 1px black;
}

.orangeBorder {
  border: solid 1px #f78310;
}

.greenBorder {
  border: solid 1px $completedStatus;
  background-color: #f0ffe6;
}

.redBorder {
  border: solid 1px $errorStatus;
  background-color: $error_bkg;
}
