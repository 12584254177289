.link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.codeInput {
  width: 2.5rem;
}

::marker {
  font-size: 1rem;
}

li {
  margin: 4px 0;
}
