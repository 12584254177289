@import '@styles/variables.scss';

.filterComponent {
  height: 2rem;
  border-radius: 3px;
  border: solid 1px $lightLineColor;
  background-color: $whiteColor;
  font-family: ApercuPro;
  font-size: 10px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.footerContainer {
  display: flex;
  background: $whiteColor;
  justify-content: end;
  background-color: $whiteColor;
  align-items: flex-end;
}

.paginationControls {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 10px;
}

.paginationButton {
  height: 2rem;
  width: 2rem !important;
  border: solid $blackColor 1px;
  background-color: $whiteColor;
  color: $blackColor;
}

.paginationButton:hover {
  background-color: $primaryColor !important;
  color: $whiteColor !important;
  border-color: $primaryColor !important;
}
