@mixin tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 100%) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

@mixin smallDesktop {
  @media (min-width: 400px) and (max-width: 1425px) {
    @content;
  }
}
