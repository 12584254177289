@use './mixin.scss' as mixin;
@import './variables.scss';

.divider {
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  width: calc(100% + 2.5rem);
  margin: 1.25rem -1.25rem;
}

.title {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.logo-title {
  letter-spacing: 4px;
}

.customMainButton {
  @extend .customButton;
}

.customSecondaryButton {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.eyeButton {
  border-color: rgba(0, 0, 0, 0.2) !important;
  box-shadow: none !important;

  &:focus {
    border-left-width: 0 !important;
  }
}

.eyeButton-invalid {
  border-color: $warningColor !important;
  box-shadow: $warningColor !important;

  &:focus {
    box-shadow: none !important;
  }
}

.p-inputtext {
  &:hover {
    border-color: rgba(0, 0, 0, 0.2) !important;
  }

  &:focus {
    box-shadow: none !important;
    border-color: $borderColor !important;

    + .eyeButton {
      box-shadow: -1 0 0 1px $borderColor !important;
      border-color: $borderColor !important;
      border-left-style: none;
    }

    &:hover {
      border-color: $borderColor !important;
    }
  }
}

.p-invalid {
  &:hover {
    border-color: $warningColor !important;
    box-shadow: $warningColor !important;
  }

  &:focus {
    box-shadow: none !important;
    border-color: $borderColor !important;

    + .eyeButton-invalid {
      box-shadow: none !important;
      border-color: $borderColor !important;
      border-left-width: 0px !important;
      border-left-style: none;
    }
  }
}

.customButton {
  background: $blackColor !important;
}

.customButton:hover {
  border: solid 1px $primaryColorHover;
}

.mainCard {
  width: 25rem;
  box-shadow: none;
  border: solid 1px $blackColor;
  margin: auto;
  margin-top: 10vh;

  @include mixin.mobile {
    width: 22rem;
  }
}

.table-layout {
  background-color: $primaryBackgroundColor;
  padding: 60px 40px 20px 40px;
  min-width: 100%;

  @include mixin.smallDesktop {
    width: fit-content;
  }
}
.disbursement-layout {
  background-color: $primaryBackgroundColor;
  padding: 75px 40px 20px 40px;
  min-width: 100%;

  @include mixin.smallDesktop {
    width: fit-content;
  }
}

.sidebar__menu-list,
.sidebar__tools-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.p-datatable-header {
  border: none;
  margin-bottom: 15px;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border: none;
  background-color: $whiteColor;
  padding: 0px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  text-overflow: ellipsis;
  flex-wrap: nowrap;
  height: auto;
}

.p-datatable-footer {
  background-color: $whiteColor !important;
  border: none !important;
}

.p-button-icon-left.pi.pi-refresh {
  background-color: $primaryColor;
  color: $whiteColor;
  border-radius: 50px;
  padding: 7px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0px 0px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0px 0px 1px;
}

.p-datatable .p-datatable-tbody > tr > td {
  height: 25px;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding-top: 3.5px !important;
  padding-bottom: 3.5px !important;
}

.p-datatable .p-datatable-thead > tr > th {
  padding-top: 12px !important;
  padding-bottom: 10px !important;
  font-weight: bold;
}

.p-column-title {
  color: $primaryColor;
}

.p-datatable-scrollable .p-datatable-wrapper {
  max-height: 50lvh;
}

.p-dropdown-label.p-inputtext {
  padding: 5px 0 5px 5px !important;
}

.p-dropdown-label.p-inputtext {
  display: flex;
  align-items: center;
}

.p-button.p-component.p-datepicker-trigger.p-button-icon-only {
  border-color: $primaryColor;
  background-color: $primaryColor;
}

// Modal styles override
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: bold;
  font-size: 40px;
}

.p-dialog.p-dialog-header.p-dialog-header-icon {
  width: 18px !important;
  height: 18px !important;
  color: $primaryColor !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: $primaryColor;
  background-color: $whiteColor;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: $primaryColor;
  background-color: $whiteColor;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  color: $primaryColor;
  background-color: $primaryColor;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background-color: $whiteColor;
  opacity: 0.8;
}

.p-dialog .p-dialog-content {
  font-size: 13px;
  letter-spacing: 0.78px;
  color: $lightTextColor;
}

.p-dialog .p-dialog-header-icons {
  position: relative;
  top: -15px;
  right: -15px;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: $primaryColor;
  font-size: 28px;
}

.p-dialog .p-dialog-footer button {
  justify-content: center;
}

.p-dialog .p-dialog-header {
  padding: 2.5rem 2rem 1.5rem 2rem;
}
.p-dialog .p-dialog-footer {
  padding: 2rem 2rem 2rem 2rem;
}

.p-dialog .p-dialog-content {
  padding: 0rem 2rem 0rem 2rem;
}

.p-calendar .p-inputtext:disabled {
  background: $secondaryBackgroundColor;
}

.p-divider-solid.p-divider-horizontal:before {
  color: $lightLineColor;
  margin-top: 10px;
}

// End of modal styles override

.p-datatable-emptymessage {
  text-align: center;
}

h4 {
  font-size: 0.9rem;
  margin: 10px 0px 20px;
}

span {
  font-size: 0.9rem;
  color: $lightIconColor;
}

ul > li {
  padding: 10px;
}

button span {
  color: inherit;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.p-datatable-emptymessage td {
  text-align: center !important;
}

.hideSectionMessage {
  opacity: 0;
  transition: opacity ease-in-out 1000ms;
}

.p-dropdown:not(.p-disabled).p-focus {
  border-color: $primaryColor;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: $primaryColor;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $secondaryBackgroundColor;
  background: $primaryColor;
}

.p-inputtext {
  color: $primaryColor;
}

input[type='text']:focus {
  border-color: $primaryColor !important;
}

input[type='text']:hover:focus {
  border-color: $primaryColor !important;
}
