@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingStyle {
  width: 129.7px;
  height: 129.7px;
  animation: spin 2s linear infinite;
}
