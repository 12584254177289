@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.sidebar {
  height: 95vh;
  background-color: white;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: none;
  bottom: 0;
  top: 0;
  position: sticky;
}

.sidebarMenuList li,
.sidebarTools li {
  padding-left: 24px;
}

.sidebarContainer {
  border-right: solid 1px $lightLineColor;
}

.icons {
  margin-right: 20px;
  color: $lightTextColor;
}

.sidebarLogout {
  justify-content: start;
  padding: 20px 0 20px 0;
  border-top: solid 1px $lightLineColor;
  width: 250px;
}

.sidebarSectionTitle {
  padding: 0 13px 0 13px;
}

.sidebarMenuList,
.sidebarToolsList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.icons {
  margin-right: 20px;
  color: $lightTextColor;
}

.sidebarLogoutLink {
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
  font-weight: bold;
  padding: 0 32px 0 32px;
}
.p-button.p-component:active,
.p-button.p-component:focus,
.p-button.p-component:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}

body .p-button.p-component.SideBar_sidebarLogoutLink:focus {
  box-shadow: none !important;
  outline: none !important;
  outline-offset: 0 !important;
}

.sidebarLogoutLink > span,
.sidebarLogoutLink > .icons {
  color: $redColor;
}

.sidebarLogoutLink {
  background-color: transparent !important;
  border: 0px;
  box-shadow: none !important;
}

.sidebarLogoutLink:hover {
  opacity: 0.6;
}

h4 {
  font-size: 0.9rem;
  margin: 10px 0px 20px;
}

span {
  font-size: 0.9rem;
  color: $lightIconColor;
}

ul > li {
  padding: 10px;
}

.selectedSidebarTab {
  background-color: $activeColor !important;
  color: black;
  opacity: 1 !important;
}
button.sidebarItem {
  padding: 0px;
  &:hover,
  &:active,
  &:focus {
    border: none !important;
    outline: none !important;
  }
}
button.selectedSidebarTab {
  background-color: $activeColor !important;
  color: black;
  opacity: 1 !important;
  padding: 0px !important;

  &:hover,
  &:active,
  &:focus {
    border: none !important;
    outline: none !important;
  }
}

.sidebarItem {
  opacity: 0.5;
  background-color: transparent;
}

.sidebarItem:hover {
  opacity: 1 !important;
  color: black;
  cursor: pointer;
}
