@use '@styles/mixin.scss' as mixin;
@import '@styles/variables.scss';

.disbursementSuccessWorkingArea {
  border-radius: 9px;
  border: solid 2px $lightLineColor;
  background-color: $whiteColor;
  margin: 0 0 28px 0;
  margin-top: 50px;
  width: 826px;
  height: auto;
  padding-top: 0px;
  padding-bottom: 0px;
}

.containerDataSuccess {
  display: flex;
  align-items: center;
  padding: 20px 0px 0px 32px;
  gap: 20px;
}

.containerTransaction {
  width: 757px;
  height: 125px;
  justify-content: center;
  flex-direction: column;
  margin: 27px 37px 0px 30px;
  background-color: $activeColor;
  border-left: 5px solid $secondaryColor;
  display: flex;
  padding-left: 20px;
}
.labelTransaction {
  font-size: 19px;
  font-weight: 700;
  color: $primaryColor !important;
}

.buttonAnotherD {
  width: 291px;
  height: 46.9px;
  border-radius: 4px;
  font-size: 17px;
  background-color: $primaryColor !important;
  color: $secondaryColor !important;
  font-weight: 700;
  display: flex;
  gap: 5px;
  span {
    font-size: 17px;
    font-weight: bold;
  }
}

.containerInformation {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 652px;
}

.containerTransId {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.labelTransactionDescription {
  font-size: 19px;
  color: $primaryColor;
}

.transactionsHistoryAdvice {
  background-color: $primaryColor;
  color: $whiteColor;
  padding: 0 20px;
  gap: 8px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.transactionsHistoryDescription {
  width: 100%;
}

.link {
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  width: min-content;
  text-decoration: underline;
  text-wrap: nowrap;
  &:hover,
  &:active {
    color: $secondaryColor;
  }
}

.containerDataSucc {
  margin: 35px 33px 0px 30px;
}
